@import 'bp';
@import 'fonts';
@import 'palette';
@import 'spacing';

$max-width-input-container: 300px;
$space-above-logo-desktop: 45px;

.aside {
    background-color: $dark-color-alpha;
    display: flex;
    height: 100vh;
}

.aside > section {
    background-color: $light-color;
    flex-grow: 1;
    height: 100%;
}

/* Drawer behavioral styles: enabled */
.aside.drawer {
    left: -100vw;
    opacity: 0;
    position: fixed;
    top: 0;
    transition: left 0.1s;
    width: 100%;
    z-index: 200;
}

// TODO will update MR: ECORD-2552
@media screen and (max-width: $bp-hamburger-shown) {
    .aside.drawer.active {
        bottom: 0;
        left: 0;
        opacity: 1;

        > section {
            overflow-y: scroll;
        }
    }
}

.aside.drawer .close {
    align-self: flex-start;
    background-color: $dark-color;
    color: $neutral-color-1;
    font-size: $fs-6;
    padding: $spacing-0;
}

.search {
    display: inline-flex;
    margin-top: $spacing-2;
    padding: 0 $spacing-4;
    width: 100%;

    // Direct targeting search input
    > input {
        width: 100%;
    }

    // Direct targetting search input container
    > div {
        width: 100%;
        
        @media (min-width: 640px) {
            max-width: $max-width-input-container;
        }

        > div {
            padding: 0;
        }
    }
}

@media screen and (min-width: 640px) {
    .aside.drawer,
    .parent {
        min-width: $w-inline-global-nav;
        width: $w-inline-global-nav;
    }

    .aside > section {
        padding-top: $space-above-logo-desktop;
    }

    /* Drawer behavioral styles: disabled */
    .aside.drawer {
        background-color: inherit;
        left: 0;
        opacity: 1;
        top: auto;
    }

    .aside.drawer .close,
    .login,
    .search {
        display: none;
    }
}
